import { DescriptionVariable } from '@gql_codegen/classifieds-content-types';
import { css } from '@styled-system/css';
import { Editor } from '@tiptap/core';
import { Select } from 'antd';

type ExternalVariableListProps = {
  variables: DescriptionVariable[];
  editor: Editor | null;
};

export const ExternalVariableList = ({
  variables,
  editor,
}: ExternalVariableListProps) => {
  if (!editor) return null;

  const insertVariable = (variable: DescriptionVariable) => {
    const marks = editor.state.selection.$head
      .marks()
      .map((mark) => mark.toJSON() as unknown);

    editor
      .chain()
      .focus()
      .insertContent([
        {
          type: 'variable',
          attrs: {
            'data-value': variable.value,
            'data-label': variable.label,
          },
          marks,
        },
      ])
      .run();
  };

  return (
    <Select
      showSearch
      placeholder="Add a variable"
      optionFilterProp="label"
      value={null}
      onChange={(value: string, option) =>
        insertVariable(
          typeof option === 'object' && !Array.isArray(option)
            ? option
            : { label: value, value },
        )
      }
      className={css({ minWidth: 300 })}
      options={variables}
      data-qa-selector="description-editor-external-variable-list"
    />
  );
};
