import {
  Category,
  CategorySchema,
  ContentTemplateValidationConfig,
} from '@gql_codegen/classifieds-content-types';
import { DEFAULT_VALIDATION_CONFIG } from '@src/shared/configs/default-validation-config';
import { omit } from 'radash';
import { z } from 'zod';

export const CreateFormSchema = z.object({
  country: z.string().min(1, { message: 'Country is required' }),
  platform: z.string().min(1, { message: 'Marketplace is required' }),
  accountId: z.string().min(1, { message: 'Account is required' }),
  category: CategorySchema,
  description: z.string().trim(),
  name: z
    .string()
    .min(1, { message: 'Template name must contain at least 1 character' })
    .max(25),
  placeholders: z
    .array(
      z.object({
        position: z
          .number({ message: 'Placeholder position is required' })
          .min(1, { message: 'Placeholder position must be at least 1' })
          .max(10, {
            message: 'Placeholder position must be less than or equal to 10',
          }),
        url: z.string(),
      }),
    )
    .min(0)
    .max(100)
    .superRefine(
      //check for unique
      (placeholders, ctx) => {
        placeholders.map((placeholder, idx) => {
          const totalPlaceholders = placeholders.filter(
            (p) => p.position === placeholder.position,
          );
          if (totalPlaceholders.length > 1) {
            return ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Placeholder positions must be unique',
              path: [idx, 'position'],
            });
          }
        });
      },
    ),
});
export type CreateFormSchemaType = z.infer<typeof CreateFormSchema>;

export const DefaultCreateFormValues = {
  country: '',
  platform: '',
  accountId: '',
  name: '',
  category: Category.Other,
  placeholders: [],
  description: '',
} satisfies CreateFormSchemaType;

export const getValidationSchema = (
  validationConfig: ContentTemplateValidationConfig = DEFAULT_VALIDATION_CONFIG,
) => {
  const baseValidation = z.object({
    country: z.string().min(1, { message: 'Country is required' }),
    platform: z.string().min(1, { message: 'Marketplace is required' }),
    accountId: z.string().min(1, { message: 'Account is required' }),
    category: z.nativeEnum(omit(Category, ['Other'])),
    name: z.string().trim(),
    description: z.string().trim(),
    placeholders: z
      .array(
        z.object({
          position: z
            .number({ message: 'Placeholder position is required' })
            .min(validationConfig.minPlaceholderPosition, {
              message: `Placeholder position must be at least ${validationConfig.minPlaceholderPosition}`,
            })
            .max(validationConfig.maxPlaceholderPosition, {
              message: `Placeholder position must be less than or equal to ${validationConfig.maxPlaceholderPosition}`,
            }),
          url: z.string().min(1),
        }),
      )
      .min(validationConfig.minPlaceholdersAllowed, {
        message: `Upload at least ${validationConfig.minPlaceholdersAllowed} placeholders`,
      })
      .max(validationConfig.maxPlaceholdersAllowed, {
        message: `Upload maximum ${validationConfig.maxPlaceholdersAllowed} placeholders`,
      })
      .superRefine(
        //check for unique
        (placeholders, ctx) => {
          placeholders.map((placeholder, idx) => {
            const totalPlaceholders = placeholders.filter(
              (p) => p.position === placeholder.position,
            );
            if (totalPlaceholders.length > 1) {
              return ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Placeholder positions must be unique',
                path: [idx, 'position'],
              });
            }
          });
        },
      ),
  });

  const otherCategoryValidation = baseValidation.merge(
    z.object({
      category: z.literal(Category.Other),
      name: z
        .string()
        .trim()
        .min(1, 'Must contain at least 1 character(s)')
        .max(25),
    }),
  );

  return z.discriminatedUnion('category', [
    baseValidation,
    otherCategoryValidation,
  ]);
};
