import { computePosition, flip, shift, size } from '@floating-ui/react';
import {
  DescriptionVariable,
  useGetDictionariesQuery,
} from '@gql_codegen/classifieds-content-types';
import { useConvertGeneratedQueryToSuspense } from '@src/shared/hooks/use-convert-generated-query-to-suspence';
import { css, cva } from '@styled-system/css';
import { Flex, HStack, VStack } from '@styled-system/jsx';
import { Content, Editor, Node } from '@tiptap/core';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import History from '@tiptap/extension-history';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { PluginKey } from '@tiptap/pm/state';
import {
  EditorContent,
  posToDOMRect,
  ReactRenderer,
  useEditor,
} from '@tiptap/react';
import Suggestion from '@tiptap/suggestion';
import { Button } from 'antd';
import { ComponentProps } from 'react';
import { ExternalVariableList } from './external-variable-list';
import { InlineVariableList, VariableListRef } from './inline-variable-list';

const variableStyleCVA = cva({
  base: {
    backgroundColor: 'orange.100',
    borderRadius: '0.4rem',
    boxDecorationBreak: 'clone',
    color: 'orange.700',
    padding: '0.15rem 0.3rem',
  },
  variants: {
    bold: {
      true: {
        fontWeight: 900,
        backgroundColor: 'orange.200',
        color: 'orange.900',
      },
    },
  },
});

const checkIfHtmlLikeElement = (element: Element): element is HTMLElement =>
  'style' in element &&
  typeof element.style === 'object' &&
  element.style !== null &&
  'position' in element.style;

const updatePosition = (editor: Editor, element: HTMLElement) => {
  const virtualElement = {
    getBoundingClientRect: () =>
      posToDOMRect(
        editor.view,
        editor.state.selection.from,
        editor.state.selection.to,
      ),
  };

  computePosition(virtualElement, element, {
    placement: 'bottom-start',
    strategy: 'absolute',
    middleware: [shift(), flip(), size()],
  })
    .then(({ x, y, strategy }) => {
      element.style.width = 'max-content';
      element.style.position = strategy;
      element.style.left = `${x}px`;
      element.style.top = `${y}px`;
    })
    .catch((error: unknown) => {
      console.error('DescriptionEditor::updatePosition', error);
    });
};

export type DescriptionEditorVariables = DescriptionVariable[];

const Variable = Node.create<{ variables: DescriptionEditorVariables }>({
  name: 'variable',
  draggable: false,
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addOptions() {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      ...this.parent?.(),
      inline: true,
      draggable: false,
      variables: [],
    };
  },

  addAttributes() {
    return {
      'data-value': {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute('data-value') ?? element.innerText;
        },
        renderHTML: (attributes) => {
          if (typeof attributes['data-value'] !== 'string') {
            return {};
          }

          return {
            'data-value': attributes['data-value'],
          };
        },
      },
      'data-label': {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute('data-label') ?? element.innerText;
        },
        renderHTML: (attributes) => {
          if (typeof attributes['data-label'] !== 'string') {
            return {};
          }

          return {
            'data-label': attributes['data-label'],
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-type="variable"]',
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    const variableIsUnknown =
      this.options.variables.find(
        (variable) => variable.value === node.attrs['data-value'],
      )?.value === undefined;

    const hasBoldMark = node.marks.some((mark) => mark.type.name === 'bold');

    return [
      'span',
      {
        ...HTMLAttributes,
        'data-type': 'variable',
        class: variableStyleCVA({ bold: hasBoldMark }),
      },

      variableIsUnknown
        ? `{{UNKNOWN_VARIABLE: ${typeof node.attrs['data-value'] === 'string' ? node.attrs['data-value'] : ''}}}`
        : node.attrs['data-label'],
    ];
  },

  renderText({ node }) {
    if (
      typeof node.attrs['data-label'] !== 'string' ||
      typeof node.attrs['data-value'] !== 'string'
    )
      return '';
    return `{{ ${node.attrs['data-label'] ?? node.attrs['data-value']} }}`;
  },

  addProseMirrorPlugins() {
    return [
      Suggestion<DescriptionVariable, DescriptionVariable>({
        editor: this.editor,
        pluginKey: new PluginKey('variable'),
        char: '{{',
        startOfLine: false,
        allowSpaces: true,
        command: ({ editor, range, props }) => {
          // increase range.to by one when the next node is of type "text"
          // and starts with a space character
          const nodeAfter = editor.view.state.selection.$to.nodeAfter;
          const overrideSpace = nodeAfter?.text?.startsWith(' ');

          if (overrideSpace) {
            range.to += 1;
          }

          const marks = editor.state.selection.$head
            .marks()
            .map((mark) => mark.toJSON() as unknown);

          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: {
                  ...props,
                  'data-value': props.value,
                  'data-label': props.label,
                },
                marks,
              },
            ])
            .run();
        },
        items: ({ query }) => {
          return this.options.variables.filter((item) =>
            item.value.toLowerCase().includes(query.toLowerCase()),
          );
        },
        render: () => {
          let component: ReactRenderer<
            VariableListRef,
            ComponentProps<typeof InlineVariableList>
          >;

          return {
            onStart: (props) => {
              component = new ReactRenderer(InlineVariableList, {
                props,
                editor: props.editor,
              });

              if (
                !props.clientRect ||
                !checkIfHtmlLikeElement(component.element)
              ) {
                return;
              }

              component.element.style.position = 'absolute';

              document.body.appendChild(component.element);

              updatePosition(props.editor, component.element);
            },

            onUpdate(props) {
              component.updateProps(props);

              if (
                !props.clientRect ||
                !checkIfHtmlLikeElement(component.element)
              ) {
                return;
              }

              updatePosition(props.editor, component.element);
            },

            onKeyDown(props) {
              if (props.event.key === 'Escape') {
                component.destroy();
                component.element.remove();

                return true;
              }

              return component.ref?.onKeyDown(props) ?? false;
            },

            onExit() {
              component.destroy();
              component.element.remove();
            },
          };
        },
      }),
    ];
  },
});

type MenuBarProps = { editor: Editor };
const MenuBar = ({ editor }: MenuBarProps) => {
  return (
    <HStack gap={1} flexWrap="wrap">
      <Button
        size="small"
        color="blue"
        variant={editor.isActive('bold') ? 'solid' : 'outlined'}
        onClick={() => editor.chain().focus().toggleBold().run()}
        title="Bold"
        data-qa-selector="description-editor-toggle-bold"
      >
        Bold
      </Button>
      <Button
        size="small"
        color="blue"
        variant={'outlined'}
        onClick={() => editor.chain().focus().setHardBreak().run()}
        data-qa-selector="description-editor-hard-break"
      >
        Hard break
      </Button>
      <Button
        size="small"
        color="blue"
        variant={'outlined'}
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
        data-qa-selector="description-editor-horizontal-rule"
      >
        Horizontal line
      </Button>
      <Button
        size="small"
        color="blue"
        variant={editor.isActive('bulletList') ? 'solid' : 'outlined'}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        data-qa-selector="description-editor-toggle-bullet-list"
      >
        Toggle bullet list
      </Button>
    </HStack>
  );
};

type DescriptionEditorProps = {
  country: string;
  onChange: (richText: string) => void;
  initialValue: Content;
};
export const DescriptionEditor = (props: DescriptionEditorProps) => {
  const { data } = useConvertGeneratedQueryToSuspense(
    useGetDictionariesQuery,
    undefined,
  );

  const { descriptionVariables } = data.getDictionaries;

  const variables =
    descriptionVariables.countrySpecificDescriptionVariables.find(
      (item) => item.country === props.country,
    )?.variables ?? descriptionVariables.defaultDescriptionVariables;

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      BulletList,
      OrderedList,
      ListItem,
      Bold,
      Italic,
      HardBreak,
      HorizontalRule,
      History,
      Placeholder.configure({
        placeholder: 'Type {{ to insert a variable or select it from the list',
      }),
      Variable.configure({
        variables,
      }),
    ],
    editorProps: {
      attributes: {
        class: css({
          minHeight: '15vh',
          border: '1px solid',
          borderColor: 'gray.300',
          borderRadius: 'md',
          padding: '10px',
          '& ul': {
            all: 'revert',
          },
          '& hr': {
            all: 'revert !important',
          },
          '& p.is-editor-empty:first-child::before': {
            color: 'gray.400',
            content: 'attr(data-placeholder)',
            float: 'left',
            height: 0,
            pointerEvents: 'none',
          },
        }),
        'data-qa-selector': 'description-editor',
      },
    },
    content: props.initialValue,
    onUpdate({ editor }) {
      props.onChange(JSON.stringify(editor.getJSON()));
    },
  });

  if (!editor) {
    return null;
  }

  return (
    <VStack className={css({ flexGrow: 1 })} alignItems="start">
      <HStack gap={4} width="100%">
        <MenuBar editor={editor} />
        <Flex flex={1}>
          <ExternalVariableList variables={variables} editor={editor} />
        </Flex>
      </HStack>
      <EditorContent editor={editor} className={css({ minWidth: '100%' })} />
    </VStack>
  );
};
