import { queryClient } from '@app/query-client';
import { EditFormSchemaType } from '@features/edit-page/model/edit-form-model';
import { useUppyZustandEditStore } from '@features/edit-page/stores/uppy-zustand-store';
import {
  Category,
  ContentTemplateUpdateInputSchema,
  GetContentTemplateQuery,
  useGetContentTemplateQuery,
  useGetTemplatesQuery,
  useUpdateContentTemplateMutation,
} from '@gql_codegen/classifieds-content-types';
import { DescriptionEditor } from '@src/shared/components/description-editor';
import { formatServerError } from '@src/shared/formating/format-server-error';
import { css } from '@styled-system/css';
import { useIsMutating } from '@tanstack/react-query';
import { JSONContent } from '@tiptap/core';
import { App, Col, Divider, Form, Input, Row, Select } from 'antd';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { EditHeader } from './edit-header';
import FileUpload from './file-upload';
import { useUppyWithEditForm } from './file-upload/hooks/use-uppy-with-form';
import { useValidationConfigQuery } from './file-upload/hooks/use-validation-dictionary';

export const EditForm = () => {
  const { notification } = App.useApp();

  const updateTemplateMutation = useUpdateContentTemplateMutation({
    throwOnError: false,
  });

  const uploads = useUppyZustandEditStore((state) => state.currentUploads);

  const validationConfigsQuery = useValidationConfigQuery();
  const isMutating = useIsMutating() > 0;

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useFormContext<EditFormSchemaType>();

  const category = useWatch({ control, name: 'category' });
  const country = useWatch({ control, name: 'country' });
  const { handleFileUpload, handleFileDelete, placeholders, clearUppy } =
    useUppyWithEditForm({
      validationConfig: validationConfigsQuery.data.config,
    });

  const handleSuccessSubmit = async (formData: EditFormSchemaType) => {
    const formDataParseResult =
      ContentTemplateUpdateInputSchema().safeParse(formData);

    if (!formDataParseResult.success)
      return notification.error({
        message: 'Something wrong with form data, please inform devs about it',
        description: 'Form data does not fit for the mutation',
      });

    try {
      await updateTemplateMutation.mutateAsync(
        { updateInput: formDataParseResult.data },
        {
          onSuccess: ({ updateContentTemplate }, { updateInput: { id } }) => {
            if (!updateContentTemplate) return;

            queryClient.setQueryData<GetContentTemplateQuery>(
              useGetContentTemplateQuery.getKey({ uuid: id }),
              () => ({
                getContentTemplate: updateContentTemplate,
              }),
            );

            void queryClient.invalidateQueries({
              queryKey: useGetTemplatesQuery.getKey(),
            });

            clearUppy();
            reset(updateContentTemplate);
          },
          onError: (error) => {
            notification.error({
              message: (
                <span data-qa-selector="edit-page-server-error-message">
                  {formatServerError(error)}
                </span>
              ),
            });
          },
        },
      );

      notification.success({
        message: (
          <span data-qa-selector="edit-page-form-success-message">
            Updated successfully!
          </span>
        ),
      });
    } catch (error) {
      console.log('[EditForm]::handleSuccessSubmit::Error =>', error);
    }
  };

  const handleInvalidSubmit = (...args: unknown[]) => {
    console.log('[EditForm::handleSubmit::Error] =>', ...args);
    return notification.error({
      message: (
        <span data-qa-selector="edit-page-form-error-message">
          Please fix form errors
        </span>
      ),
    });
  };

  const isSaveButtonBlocked =
    Object.keys(uploads).length > 0 || isMutating || isSubmitting;

  const isNotOtherCategory = category !== Category.Other;

  return (
    <>
      <Form
        onReset={() => {
          clearUppy();
          reset();
        }}
        onFinish={(event: React.BaseSyntheticEvent) => {
          void handleSubmit(handleSuccessSubmit, handleInvalidSubmit)(event);
        }}
        layout="vertical"
      >
        <EditHeader isSubmitting={isSaveButtonBlocked} />
        <Row gutter={24} className={css({ marginBottom: 4 })}>
          <Col span={6}>
            <FormItem
              control={control}
              name="country"
              label="Country"
              required
              data-qa-selector="edit-page-country"
            >
              <Select disabled data-qa-selector="edit-page-country-select" />
            </FormItem>
          </Col>

          <Col span={6}>
            <FormItem
              control={control}
              name="platform"
              label="Marketplace"
              required
              data-qa-selector="edit-page-marketplace"
            >
              <Select
                disabled
                data-qa-selector="edit-page-marketplace-select"
              />
            </FormItem>
          </Col>

          <Col span={6}>
            <FormItem
              control={control}
              name="account"
              label="Account"
              required
              data-qa-selector="edit-page-account"
            >
              <Select disabled data-qa-selector="edit-page-account-select" />
            </FormItem>
          </Col>

          <Col span={6}>
            <FormItem
              control={control}
              name="category"
              label="Category"
              required
              data-qa-selector="edit-page-category"
            >
              <Select disabled data-qa-selector="edit-page-category-select" />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24} className={css({ marginBottom: 4 })}>
          <Col span={12}>
            <FormItem
              control={control}
              name="name"
              label="Content Template Name"
              required
              data-qa-selector="edit-page-name"
            >
              <Input
                maxLength={isNotOtherCategory ? undefined : 25}
                disabled={isNotOtherCategory}
                count={{
                  max: 25,
                  show: ({ count, maxLength }) =>
                    !isNotOtherCategory && (
                      <span data-qa-selector="edit-page-name-counter">{`${count}/${maxLength ?? 25}`}</span>
                    ),
                }}
                data-qa-selector="edit-page-name-input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Divider
            orientation="left"
            className={css({
              my: '8 !important',
            })}
          >
            <span
              className={css({
                fontSize: '2xl',
                color: 'gray.400',
              })}
            >
              Placeholders
            </span>
          </Divider>
          <Col>
            <FileUpload
              fileLimit={{
                max: validationConfigsQuery.data.config.maxPlaceholdersAllowed,
                min: validationConfigsQuery.data.config.minPlaceholdersAllowed,
              }}
              handleFileUpload={handleFileUpload}
              handleFileDelete={handleFileDelete}
              placeholders={placeholders}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Divider
            orientation="left"
            className={css({
              my: '8 !important',
            })}
          >
            <span
              className={css({
                fontSize: '2xl',
                color: 'gray.400',
              })}
            >
              Description Editor
            </span>
          </Divider>
          <Controller
            control={control}
            name="description"
            render={({ field }) => {
              const descriptionValue = field.value
                ? (JSON.parse(field.value) as JSONContent)
                : field.value.toString();

              return (
                <DescriptionEditor
                  country={country}
                  onChange={field.onChange}
                  initialValue={descriptionValue}
                />
              );
            }}
          />
        </Row>
      </Form>
    </>
  );
};
