import type { QueryClient } from '@tanstack/react-query';
import {
  createRootRouteWithContext,
  HeadContent,
  Outlet,
} from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const RouterRootComponent = () => (
  <>
    <HeadContent />
    <Outlet />
    {import.meta.env.DEV && (
      <>
        <TanStackRouterDevtools position="bottom-left" />
        <ReactQueryDevtools buttonPosition="bottom-right" />
      </>
    )}
  </>
);
export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  component: RouterRootComponent,
  head: () => {
    return {
      meta: [{ title: 'Backoffice Classified-Content' }],
    };
  },
});
