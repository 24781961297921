import { Form as AntdForm } from 'antd';
import type { NamePath } from 'antd/es/form/interface';
import { Children, cloneElement, isValidElement, useEffect } from 'react';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';

type AntdFormItemProps = React.ComponentProps<typeof AntdForm.Item>;

type ChildElementProps = {
  onChange?: (...args: unknown[]) => void;
  onBlur?: () => void;
  [key: string]: unknown;
};

export type FormItemProps<TFieldValues extends FieldValues = FieldValues> = {
  children: React.ReactElement<ChildElementProps>;
  controllerProps: UseControllerProps<TFieldValues>;
  overrideFieldOnChange?: (...args: unknown[]) => void;
  valuePropName?: string;
} & Omit<AntdFormItemProps, 'name' | 'rules' | 'validateStatus'>;

// TODO: Support `onBlur` `ref` `reset`
export const FormItemWithRules = <TFieldValues extends FieldValues>({
  children,
  help,
  valuePropName,
  overrideFieldOnChange,
  controllerProps,
  ...props
}: FormItemProps<TFieldValues>) => {
  const { field, fieldState } = useController<TFieldValues>(controllerProps);
  const form = AntdForm.useFormInstance();

  useEffect(() => {
    form.setFieldValue(controllerProps.name, field.value);
  }, [field.value, form, controllerProps.name]);

  return (
    <AntdForm.Item
      {...props}
      name={controllerProps.name as NamePath<string>}
      initialValue={field.value}
      validateStatus={fieldState.invalid ? 'error' : ''}
      help={fieldState.error?.message ?? help}
    >
      {
        // eslint-disable-next-line @eslint-react/no-children-map
        Children.map(children, (child) => {
          if (!isValidElement(child)) {
            return child;
          }

          // Define expected child props

          const typedChild = child as React.ReactElement<ChildElementProps>;
          const { onChange: childOnChange, onBlur: childOnBlur } =
            typedChild.props;

          // Create handlers with correct types
          const handleChange = (...args: unknown[]) => {
            childOnChange?.(...args);

            if (overrideFieldOnChange) {
              overrideFieldOnChange(...args);
            } else {
              field.onChange(...args);
            }
          };

          const handleBlur = () => {
            childOnBlur?.();
            field.onBlur();
          };

          // Prepare new props for the child element
          const newProps: ChildElementProps = {
            name: field.name,
            ref: field.ref,
            onChange: handleChange,
            onBlur: handleBlur,
          };

          if (valuePropName) {
            newProps[valuePropName] = field.value;
          } else {
            newProps['value'] = field.value;
          }

          // eslint-disable-next-line @eslint-react/no-clone-element
          return cloneElement(typedChild, newProps);
        })
      }
    </AntdForm.Item>
  );
};
