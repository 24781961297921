import { DescriptionVariable } from '@gql_codegen/classifieds-content-types';
import { css, cva } from '@styled-system/css';
import { Editor } from '@tiptap/core';
import { SuggestionProps } from '@tiptap/suggestion';
import { forwardRef, Key, useImperativeHandle, useState } from 'react';

const variableListCVA = cva({
  base: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5rem 1rem',
    borderRadius: '0.5rem',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease-in-out',
    _hover: {
      backgroundColor: 'gray.100',
    },
  },
  variants: {
    selected: {
      true: {
        backgroundColor: 'gray.200',
      },
    },
  },
});

export type VariableListRef = {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean;
};
type VariableListProps = SuggestionProps<
  DescriptionVariable,
  DescriptionVariable
> & {
  editor: Editor;
  key: Key;
};

//TODO: update to ref prop whne tiptap will support react 19
// eslint-disable-next-line @eslint-react/no-forward-ref
export const InlineVariableList = forwardRef<
  VariableListRef,
  VariableListProps
>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [prevItems, setPrevItems] = useState(props.items);

  if (props.items !== prevItems) {
    setPrevItems(props.items);
    setSelectedIndex(0);
  }

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (!item) return console.log('VariableList: No item found');
    props.command(item);
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length,
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div
      className={css({
        background: 'white',
        border: '1px solid',
        borderColor: 'gray.50',
        borderRadius: '0.7rem',
        boxShadow: 'black',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.1rem',
        overflow: 'auto',
        padding: '0.4rem 0.2rem',
        position: 'relative',
        shadow: 'xl',
      })}
    >
      {props.items.length ? (
        props.items.map((item, index) => (
          <button
            type="button"
            className={variableListCVA({ selected: index === selectedIndex })}
            key={item.value}
            onClick={() => selectItem(index)}
          >
            {item.label}
          </button>
        ))
      ) : (
        <div className="item">No result</div>
      )}
    </div>
  );
});
