/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root';
import { Route as LangClassifiedsContentIndexImport } from './routes/$lang/classifieds-content/index';
import { Route as LangClassifiedsContentCreateIndexImport } from './routes/$lang/classifieds-content/create/index';
import { Route as LangClassifiedsContentEditEditIDImport } from './routes/$lang/classifieds-content/edit/$editID';

// Create/Update Routes

const LangClassifiedsContentIndexRoute =
  LangClassifiedsContentIndexImport.update({
    id: '/$lang/classifieds-content/',
    path: '/$lang/classifieds-content/',
    getParentRoute: () => rootRoute,
  } as any);

const LangClassifiedsContentCreateIndexRoute =
  LangClassifiedsContentCreateIndexImport.update({
    id: '/$lang/classifieds-content/create/',
    path: '/$lang/classifieds-content/create/',
    getParentRoute: () => rootRoute,
  } as any);

const LangClassifiedsContentEditEditIDRoute =
  LangClassifiedsContentEditEditIDImport.update({
    id: '/$lang/classifieds-content/edit/$editID',
    path: '/$lang/classifieds-content/edit/$editID',
    getParentRoute: () => rootRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/$lang/classifieds-content/': {
      id: '/$lang/classifieds-content/';
      path: '/$lang/classifieds-content';
      fullPath: '/$lang/classifieds-content';
      preLoaderRoute: typeof LangClassifiedsContentIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/$lang/classifieds-content/edit/$editID': {
      id: '/$lang/classifieds-content/edit/$editID';
      path: '/$lang/classifieds-content/edit/$editID';
      fullPath: '/$lang/classifieds-content/edit/$editID';
      preLoaderRoute: typeof LangClassifiedsContentEditEditIDImport;
      parentRoute: typeof rootRoute;
    };
    '/$lang/classifieds-content/create/': {
      id: '/$lang/classifieds-content/create/';
      path: '/$lang/classifieds-content/create';
      fullPath: '/$lang/classifieds-content/create';
      preLoaderRoute: typeof LangClassifiedsContentCreateIndexImport;
      parentRoute: typeof rootRoute;
    };
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/$lang/classifieds-content': typeof LangClassifiedsContentIndexRoute;
  '/$lang/classifieds-content/edit/$editID': typeof LangClassifiedsContentEditEditIDRoute;
  '/$lang/classifieds-content/create': typeof LangClassifiedsContentCreateIndexRoute;
}

export interface FileRoutesByTo {
  '/$lang/classifieds-content': typeof LangClassifiedsContentIndexRoute;
  '/$lang/classifieds-content/edit/$editID': typeof LangClassifiedsContentEditEditIDRoute;
  '/$lang/classifieds-content/create': typeof LangClassifiedsContentCreateIndexRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/$lang/classifieds-content/': typeof LangClassifiedsContentIndexRoute;
  '/$lang/classifieds-content/edit/$editID': typeof LangClassifiedsContentEditEditIDRoute;
  '/$lang/classifieds-content/create/': typeof LangClassifiedsContentCreateIndexRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | '/$lang/classifieds-content'
    | '/$lang/classifieds-content/edit/$editID'
    | '/$lang/classifieds-content/create';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | '/$lang/classifieds-content'
    | '/$lang/classifieds-content/edit/$editID'
    | '/$lang/classifieds-content/create';
  id:
    | '__root__'
    | '/$lang/classifieds-content/'
    | '/$lang/classifieds-content/edit/$editID'
    | '/$lang/classifieds-content/create/';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  LangClassifiedsContentIndexRoute: typeof LangClassifiedsContentIndexRoute;
  LangClassifiedsContentEditEditIDRoute: typeof LangClassifiedsContentEditEditIDRoute;
  LangClassifiedsContentCreateIndexRoute: typeof LangClassifiedsContentCreateIndexRoute;
}

const rootRouteChildren: RootRouteChildren = {
  LangClassifiedsContentIndexRoute: LangClassifiedsContentIndexRoute,
  LangClassifiedsContentEditEditIDRoute: LangClassifiedsContentEditEditIDRoute,
  LangClassifiedsContentCreateIndexRoute:
    LangClassifiedsContentCreateIndexRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/$lang/classifieds-content/",
        "/$lang/classifieds-content/edit/$editID",
        "/$lang/classifieds-content/create/"
      ]
    },
    "/$lang/classifieds-content/": {
      "filePath": "$lang/classifieds-content/index.tsx"
    },
    "/$lang/classifieds-content/edit/$editID": {
      "filePath": "$lang/classifieds-content/edit/$editID.tsx"
    },
    "/$lang/classifieds-content/create/": {
      "filePath": "$lang/classifieds-content/create/index.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
